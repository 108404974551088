import {
  CONNECTED_BANNER_HEIGHT,
  HEADER_DEFAULT_HEIGHT,
  HEADER_DEFAULT_MENU_HEIGHT,
  HEADER_DEFAULT_MOBILE_HEIGHT,
  HEADER_SHORT_HEIGHT,
  HEADER_SHORT_MENU_HEIGHT,
  HEADER_SHORT_MOBILE_HEIGHT,
} from '@core/constant';
import { EHeader } from '@core/type';

export const getHeaderContainerHeight = (shouldShowBanner: boolean, headerType: EHeader) => {
  if (shouldShowBanner && headerType === EHeader.DEFAULT) {
    return `calc(${HEADER_DEFAULT_HEIGHT} + ${CONNECTED_BANNER_HEIGHT})`;
  }

  if (shouldShowBanner && headerType === EHeader.SHORT) {
    return `calc(${HEADER_SHORT_HEIGHT} + ${CONNECTED_BANNER_HEIGHT})`;
  }

  if (headerType === EHeader.SHORT) {
    return HEADER_SHORT_HEIGHT;
  }

  return HEADER_DEFAULT_HEIGHT;
};

export const getHeaderContainerMobileHeight = (shouldShowBanner: boolean, headerType: EHeader) => {
  if (shouldShowBanner && headerType === EHeader.DEFAULT) {
    return `calc(${HEADER_DEFAULT_MOBILE_HEIGHT} + ${CONNECTED_BANNER_HEIGHT})`;
  }

  if (shouldShowBanner && headerType === EHeader.SHORT) {
    return `calc(${HEADER_SHORT_MOBILE_HEIGHT} + ${CONNECTED_BANNER_HEIGHT})`;
  }

  if (headerType === EHeader.SHORT) {
    return HEADER_SHORT_MOBILE_HEIGHT;
  }

  return HEADER_DEFAULT_MOBILE_HEIGHT;
};

export const getHeaderTranslateY = (headerType: EHeader, isBannerOpen: boolean) => {
  if (headerType === EHeader.DEFAULT) {
    return `translateY(calc(${HEADER_DEFAULT_MENU_HEIGHT} - ${HEADER_DEFAULT_HEIGHT} - ${
      isBannerOpen ? CONNECTED_BANNER_HEIGHT : '0px'
    }))`;
  }

  return `translateY(calc(${HEADER_SHORT_MENU_HEIGHT} - ${HEADER_SHORT_HEIGHT} - ${
    isBannerOpen ? CONNECTED_BANNER_HEIGHT : '0px'
  }))`;
};

export const getHeaderMobileTranslateY = (headerType: EHeader, isBannerOpen: boolean) => {
  if (headerType === EHeader.DEFAULT) {
    return `translateY(calc(-${HEADER_DEFAULT_MOBILE_HEIGHT} - ${
      isBannerOpen ? CONNECTED_BANNER_HEIGHT : '0px'
    }))`;
  }

  return `translateY(calc(-${HEADER_DEFAULT_MOBILE_HEIGHT} - ${
    isBannerOpen ? CONNECTED_BANNER_HEIGHT : '0px'
  }))`;
};
