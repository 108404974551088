import { ELanguageTags, ERouting } from '@core/type';

export const SANITY_CDN = 'cdn.sanity.io';
export const SANITY_API = '*.api.sanity.io';
export const SANITY_API_CDN = '*.apicdn.sanity.io';
export const UNSPLASH_SOURCE = 'source.unsplash.com';

type SanityPageRouteType = {
  [key in ELanguageTags]: Partial<{
    [key in ERouting]: string;
  }>;
};

export const SANITY_PAGE_ROUTE: SanityPageRouteType = {
  [ELanguageTags.DEFAULT]: {
    [ERouting.HOME_BUY]: 'buy',
    [ERouting.PRODUCT_SHOW]: 'product',
  },
  [ELanguageTags.EN_US]: {
    [ERouting.HOME_BUY]: 'buy',
    [ERouting.PRODUCT_SHOW]: 'product',
  },
  [ELanguageTags.FR_FR]: {
    [ERouting.HOME_BUY]: 'acheter',
    [ERouting.PRODUCT_SHOW]: 'produit',
  },
  [ELanguageTags.IT_IT]: {
    [ERouting.HOME_BUY]: 'comprare',
    [ERouting.PRODUCT_SHOW]: 'prodotto',
  },
  [ELanguageTags.DE_DE]: {
    [ERouting.HOME_BUY]: 'kaufen',
    [ERouting.PRODUCT_SHOW]: 'produkt',
  },
};
