import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import { headerHeightContext, useContextRouting } from '@core/context';
import { EHeader, ERouting, ThemeProps } from '@core/type';
import { HeaderHeightHook, HeaderHeightState } from '@core/type/context';
import { getTypeRoute } from '@core/util';

import { storeHook } from '../helpers';
import { useHeaderHeight } from '../hook';

export const WrappedProviderHeaderHeight = ({
  children,
  height,
}: {
  children?: ReactNode;
  height?: string;
}) => {
  const hooks = storeHook<HeaderHeightState, HeaderHeightHook>(
    {
      height,
    },
    useHeaderHeight,
  );
  return <headerHeightContext.Provider value={hooks}>{children}</headerHeightContext.Provider>;
};

export const ProviderHeaderHeight = ({ children }: { children?: ReactNode }) => {
  const { keyRoute } = useContextRouting();
  const {
    structure: {
      header: {
        blog: { height: heightBlog },
        short: { height: heightShort },
        signUp: { height: heightSignUp },
        height: heightDefault,
      },
    },
  } = useTheme() as ThemeProps;

  let height: string = heightDefault;
  if (keyRoute === ERouting.SIGN_UP) {
    height = heightSignUp;
  } else if (getTypeRoute(keyRoute)?.header?.type === EHeader.BLOG) {
    height = heightBlog;
  } else if (
    getTypeRoute(keyRoute)?.header?.type === EHeader.SHORT ||
    getTypeRoute(keyRoute)?.header?.type === EHeader.LANDING
  ) {
    height = heightShort;
  }

  return <WrappedProviderHeaderHeight height={height}>{children}</WrappedProviderHeaderHeight>;
};
