import { setIdUrl } from '../helpers/api';

const USER = '/user';

class Auth {
  private static readonly BASE = '';

  public static readonly SIGNUP = `${USER}/sign_up`;
  public static readonly LOGIN = `${Auth.BASE}/session`;
  public static readonly LOGOUT = `${Auth.BASE}/session`;
  public static readonly SMS = `${Auth.BASE}/session/mfa/sms`;
  public static readonly TOTP = `${Auth.BASE}/session/mfa/totp`;
  public static readonly REFRESH_TOKEN = `${Auth.BASE}/session`;
  public static readonly CHANGE_PASSWORD = `${Auth.BASE}/password`;
  public static readonly UNLOCK_ACCOUNT = `${Auth.BASE}/unlock`;
  public static readonly FORGOTTEN_PASSWORD = `${Auth.BASE}/password`;
}

class Content {
  private static readonly BASE = `/blog`;

  public static readonly BLOG = `${Content.BASE}/articles`;
  public static readonly BLOG_CATEGORIES = `${Content.BASE}/categories`;
  public static readonly BLOG_BY_SLUG = setIdUrl(`${Content.BASE}/articles`, ':slug');
  public static readonly HOMEPAGE_ARTICLES = `${Content.BASE}/homepage_articles`;
  public static readonly HIGHLIGHTED_ARTICLE = `${Content.BASE}/highlighted_article`;
  public static readonly CATEGORY_BY_SLUG = `${Content.BASE}/categories/:slug/articles`;
  public static readonly TAG_BY_SLUG = `${Content.BASE}/tags/:slug/articles`;
  public static readonly SEARCH = `${Content.BASE}/search`;
  public static readonly SEARCH_AUTOCOMPLETE = `${Content.BASE}/search/autocomplete`;
}

class Countries {
  public static readonly COUNTRIES = '/countries';
}

class MarketAlert {
  private static readonly BASE = `${USER}/alerts`;

  public static readonly ALERTS = `${MarketAlert.BASE}/markets`;
  public static readonly DELETE_ALERT = setIdUrl(`${MarketAlert.BASE}/markets`, ':alertId');
}

class ProductAlert {
  private static readonly BASE = `${USER}/alerts`;

  public static readonly PRODUCT_ALERTS = `${ProductAlert.BASE}/products`;
  public static readonly UPDATE_PRODUCT_ALERT = setIdUrl(
    `${ProductAlert.BASE}/products`,
    ':alertId',
  );
  public static readonly DELETE_PRODUCT_ALERT = setIdUrl(
    `${ProductAlert.BASE}/products`,
    ':alertId',
  );
}

class StockAlert {
  private static readonly BASE = `${USER}/alerts`;

  public static readonly ALERTS = `${StockAlert.BASE}/stocks`;
  public static readonly UPDATE_ALERT = setIdUrl(`${StockAlert.BASE}/stocks`, ':alertId');
  public static readonly DELETE_ALERT = setIdUrl(`${StockAlert.BASE}/stocks`, ':alertId');
}

class Categories {
  public static readonly CATEGORIES = '/categories';
  public static readonly CATEGORY = setIdUrl('/categories', ':slug');
}

class KYC {
  private static readonly BASE = `${USER}/kyc`;

  public static readonly KYC = `/kyc`;
  public static readonly USER_KYC = KYC.BASE;
  public static readonly STEP = setIdUrl(KYC.BASE, ':slug');
  public static readonly UTILITY_BILLS = `${KYC.BASE}/utility_bills`;
}

class Onfido {
  private static ONFIDO_BASE = '/onfido';
  public static readonly ONFIDO_SDK = `${USER}${Onfido.ONFIDO_BASE}/sdk_token`;
  public static readonly UPLOAD_DOCUMENTS = `${Onfido.ONFIDO_BASE}/upload_documents`;
}

class StaticPage {
  public static readonly STATIC_PAGE_BY_SLUG = setIdUrl('/static_pages', ':slug');
  public static readonly STATIC_PAGE_BY_SLUG_WITH_AUTH = setIdUrl('/user/static_pages', ':slug');
}

class Tool {
  public static readonly PING = `/ping`;
}

class Price {
  public static readonly SPOT_PRICES = '/spot_prices';
}

class Subscribe {
  public static readonly SUBSCRIBE_EMAIL = `/subscribe`;
}

class Search {
  public static readonly AUTOCOMPLETE = `/products/search/autocomplete`;
}

class SavingAssistant {
  public static readonly INVESTMENT_ASSISTANT = `/investment_assistant`;
}

class User {
  public static readonly PROFILE = `${USER}/profile`;
  public static readonly AUTO_SAVINGS_PRODUCTS = `${USER}/auto_savings`;
  public static readonly AUTO_SAVINGS_PRODUCT_BY_ID = setIdUrl(`${USER}/auto_savings`, ':id');
  public static readonly AUTO_SAVINGS_PAUSE = setIdUrl(`${USER}/auto_savings`, ':id', 'pause');
  public static readonly AUTO_SAVINGS_UNPAUSE = setIdUrl(`${USER}/auto_savings`, ':id', 'unpause');
  public static readonly EMERGENCY_PEOPLE = `${USER}/profile/emergency_people`;
  public static readonly LOGIN_ALERT = `${USER}/profile/login_alert`;
  public static readonly PURCHASING_LIMIT = `${USER}/purchasing_limit`;
  public static readonly ADDRESSES = `${USER}/profile/addresses`;
  public static readonly CERTIFICATE_DATES = `${USER}/profile/certificate_dates`;
  public static readonly NEWSLETTER_SUBSCRIPTIONS_PROFILE = `${USER}/profile/newsletter_subscriptions`;
  public static readonly CLAIM = `${USER}/claim`;
  public static readonly CONFIRM_CLAIM = `${USER}/claim/confirm`;
  public static readonly REVIEW_CLAIM = `${USER}/claim/review`;
  public static readonly HISTORY_CLAIM = setIdUrl(`${USER}/history/claims`, ':id');
  public static readonly GIFTS = `${USER}/gifts`;
  public static readonly GIFT = setIdUrl(`${USER}/gifts`, ':referenceNumber');
  public static readonly GIFTS_RELATIONSHIPS = `${USER}/gifts/relationships`;
  public static readonly GIFTS_CONFIRM = `${USER}/gifts/confirm`;
  public static readonly CERTIFICATE = `${USER}/profile/certificate`;
  public static readonly GIFT_ACCEPT = `${setIdUrl(`${USER}/gifts`, ':referenceNumber')}/accept`;
  public static readonly GIFT_DECLINE = `${setIdUrl(`${USER}/gifts`, ':referenceNumber')}/decline`;
  public static readonly WALLET = `${USER}/profile/wallet`;
  public static readonly WALLET_SALES = `${USER}/profile/wallet/sales`;
  public static readonly WALLET_SALES_CONFIRM = `${USER}/profile/wallet/sales/confirm`;
  public static readonly DEPOSIT_DETAILS = `${USER}/deposit_details`;
  public static readonly NEWSLETTER_SUBSCRIPTIONS = `/newsletter_subscriptions`;
  public static readonly PASSWORD_SETTINGS = `${USER}/profile/password`;
  public static readonly TOTP = `${USER}/mfa/totp`;
  public static readonly BANK_ACCOUNTS = `${USER}/bank_accounts`;
  public static readonly WITHDRAWALS = `${USER}/withdrawals`;
  public static readonly SMS = `${USER}/mfa/sms`;
  public static readonly STORAGE_FEES = `${USER}/storage_fees`;
  public static readonly STORAGE_FEES_INVOICES = `${USER}/storage_fees/invoices`;
  public static readonly HISTORY_ORDERS = `${USER}/history/orders`;
  public static readonly HISTORY_ORDER_SHOW = setIdUrl(`${USER}/history/orders`, ':id');
  public static readonly HISTORY_GIFTS = `${USER}/history/gifts`;
  public static readonly HISTORY_GIFT_SHOW = setIdUrl(`${USER}/history/gifts`, ':reference_number');
  public static readonly HISTORY_CLAIMS = `${USER}/history/claims`;
  public static readonly HISTORY_CLAIM_SHOW = setIdUrl(`${USER}/history/claims`, ':id');
  public static readonly REFERRAL_PROGRAM = `${USER}/referral_program`;
  public static readonly REFERRAL_PROGRAM_ADDRESS = `${USER}/referral_program/address`;
}

class Product {
  private static readonly BASE = '/products';

  public static readonly AUTO_SAVINGS = `${Product.BASE}/auto_saving`;
  public static readonly SEARCH = `${Product.BASE}/search`;
  public static readonly PRODUCT_BY_SLUG = setIdUrl(Product.BASE, ':slug');
  public static readonly PRODUCT_BY_ID = setIdUrl(Product.BASE, ':id');
  public static readonly PRODUCT_SILVER_LP = `${Product.BASE}/silver_lp`;
  public static readonly PRODUCT_HOMEPAGE = `${Product.BASE}/homepage`;
}

class AccountTransactions {
  private static readonly BASE = '/account_transactions';

  public static readonly GA_PAY = `${AccountTransactions.BASE}/goldavenue_pay`;
  public static readonly STORAGE_FEE_CLAIMS = `${AccountTransactions.BASE}/storage_fee_claims`;
}

class WebSocket {
  public static readonly CABLE = `/cable`;
}

class Wishlist {
  public static readonly BASE = `${USER}/wishlist`;

  public static readonly WISHLIST = Wishlist.BASE;
  public static readonly ADD_TO_WISHLIST = `${Wishlist.BASE}/products?product_id=[id]`;
  public static readonly DELETE_FROM_WISHLIST = setIdUrl(`${Wishlist.BASE}/products`, ':productId');
}

class Sitemap {
  public static readonly BASE = 'sitemap';

  public static readonly PRODUCTS = `${Sitemap.BASE}/shop/products`;
  public static readonly ARTICLES = `${Sitemap.BASE}/blog/articles`;
  public static readonly BLOG_CATEGORIES = `${Sitemap.BASE}/blog/categories`;
  public static readonly BLOG_TAGS = `${Sitemap.BASE}/blog/tags`;
  public static readonly PRECIOUS_METAL_ARTICLES = `${Sitemap.BASE}/precious_metal/articles`;
  public static readonly SUBCATEGORIES = `${Sitemap.BASE}/shop/subcategories`;
  public static readonly TUTORIAL_VIDEOS = `${Sitemap.BASE}/tutorial_videos`;
  public static readonly FAQ = `${Sitemap.BASE}/faq/articles`;
}

class MostPurchasedProducts {
  public static readonly MOST_PURCHASED_PRODUCTS = `${USER}/most_purchased_products?quantity=4`;
  public static readonly MOST_PURCHASED_PRODUCTS_VISITOR = `/most_purchased_products?quantity=4`;
}

class ForecastedDelivery {
  public static readonly FORECASTED_DELIVERY = '/forecasted_delivery';
}

class Discount {
  public static readonly DISCOUNT_CODES = '/public_discount_code';
}

class CurrencyRates {
  public static readonly CURRENCY_RATES = '/currency_rates';
}

class CustomerSurveys {
  public static readonly CUSTOMER_SURVEYS = `${USER}/customer_surveys`;
}

class TutorialVideos {
  public static readonly TUTORIAL_VIDEO_BY_SLUG = setIdUrl('/tutorial_videos', ':slug');
  public static readonly TUTORIAL_VIDEOS = '/tutorial_videos';
}

class TeamMembers {
  public static readonly TEAM_MEMBERS = '/team_members';
}

class Banner {
  public static readonly BANNER_INFORMATION = '/banner_information';
}

class DeliveryCountries {
  public static readonly DELIVERY_COUNTRIES = '/delivery_countries';
}

class LiveCharts {
  public static readonly LIVE_CHARTS = '/live_chart';
  public static readonly INVESTMENT_CHARTS = '/potential_earnings';
}

class Trustpilot {
  public static readonly TOTAL_USERS = '/total_users';
}

class FAQ {
  public static readonly ARTICLE = setIdUrl('/faq/articles', ':slug');
  public static readonly CATEGORY = setIdUrl('/faqs', ':slug');
  public static readonly CATEGORIES = '/faq/categories';
  public static readonly SEARCH = '/faq/search';
  public static readonly SEARCH_AUTOCOMPLETE = '/faq/search/autocomplete';
}

class PreciousMetalArticles {
  private static readonly BASE = '/precious_metal';

  public static readonly PRECIOUS_METAL_ARTICLES = `${PreciousMetalArticles.BASE}/articles`;
  public static readonly PRECIOUS_METAL_ARTICLES_BY_SLUG = setIdUrl(
    PreciousMetalArticles.PRECIOUS_METAL_ARTICLES,
    ':slug',
  );
}

class LeadMagnet {
  public static readonly LEAD_MAGNET = '/lead_magnet';
}

class Shop {
  public static readonly BASE = '/shop';
  private static readonly FILTER = `${Shop.BASE}/filters`;

  public static readonly WEIGHT_RANGE = `${Shop.FILTER}/weight_ranges`;
  public static readonly PRICE_RANGE = `${Shop.FILTER}/price_ranges`;
  public static readonly PRODUCTS_TYPE = `${Shop.FILTER}/filter_product_types`;
  public static readonly COLLECTIONS = `${Shop.FILTER}/collections`;
  public static readonly BRANDS = `${Shop.FILTER}/brands`;
  public static readonly SELECTIONS = `${Shop.FILTER}/selections`;

  public static readonly PRODUCTS = `${Shop.BASE}/products`;
  public static readonly SUBCATEGORIES_PRODUCTS = `${Shop.BASE}/subcategories/:id`;
}

class CreditCard {
  public static readonly CREDIT_CARDS = `${USER}/credit_cards`;
  public static readonly FRAUD = `${CreditCard.CREDIT_CARDS}/fraud_attempts`;
  public static readonly NEW = `${CreditCard.CREDIT_CARDS}/new_cards`;
  public static readonly INITIALIZE = `${CreditCard.CREDIT_CARDS}/initialize`;
  public static readonly PAYMENT_TRANSACTIONS = `${CreditCard.CREDIT_CARDS}/payment_transactions`;
}

class Gift {
  public static readonly GIFT_INFORMATION = setIdUrl('/gifts', ':slug');
}

class Collections {
  private static readonly BASE = '/collections';

  public static readonly PRODUCT_GROUPS = setIdUrl(`${Collections.BASE}/product_groups`, ':slug');
  public static readonly BANNERS = setIdUrl(`${Collections.BASE}/banners`, ':slug');
  public static readonly ARTICLES = setIdUrl(`${Collections.BASE}/blog/articles`, ':slug');
}

class Cart {
  public static readonly CART = `${USER}/cart`;
  public static readonly ORDER = `${USER}/order`;
  public static readonly ORDER_REVIEW = `${USER}/order/review`;
  public static readonly HISTORY_ORDER = `${USER}/history/orders`;
  public static readonly ORDER_CONFIRM = `${USER}/order/confirm`;
  public static readonly VIRTUAL_CART = `/virtual_cart`;
  public static readonly SET_CART_TO_ABANDONED_CART = setIdUrl(`${USER}/cart/retrieve_cart`, ':id');
}

class Contact {
  public static readonly CONTACT_FORM = '/contact_form';
}

class Widget {
  public static readonly SPOT_PRICE_HISTORY = '/spot_price_history';

  private static readonly DAILY_SPOT_PRICE_BASE = '/spot_price_daily_stats';
  public static readonly DAILY_SPOT_PRICE_CARATS = `${Widget.DAILY_SPOT_PRICE_BASE}/carats`;
  public static readonly DAILY_SPOT_PRICE_CURRENCIES = `${Widget.DAILY_SPOT_PRICE_BASE}/currencies`;
  public static readonly DAILY_SPOT_PRICE_WEIGHTS = `${Widget.DAILY_SPOT_PRICE_BASE}/weights`;

  public static readonly PAGE_RATING = setIdUrl('/ratings/pages', ':id');
}

class Sanity {
  public static readonly SYNCHRONIZE = '/sanity/external_resources/synchronize';
}

export class Api {
  public static readonly auth = Auth;
  public static readonly content = Content;
  public static readonly tool = Tool;
  public static readonly kyc = KYC;
  public static readonly shop = Shop;
  public static readonly staticPage = StaticPage;
  public static readonly search = Search;
  public static readonly price = Price;
  public static readonly product = Product;
  public static readonly subscribe = Subscribe;
  public static readonly accountTransactions = AccountTransactions;
  public static readonly user = User;
  public static readonly webSocket = WebSocket;
  public static readonly marketAlert = MarketAlert;
  public static readonly productAlert = ProductAlert;
  public static readonly categories = Categories;
  public static readonly countries = Countries;
  public static readonly wishlist = Wishlist;
  public static readonly sitemap = Sitemap;
  public static readonly mostPurchasedProducts = MostPurchasedProducts;
  public static readonly stockAlert = StockAlert;
  public static readonly discount = Discount;
  public static readonly tutorialVideos = TutorialVideos;
  public static readonly teamMembers = TeamMembers;
  public static readonly currencyRates = CurrencyRates;
  public static readonly banner = Banner;
  public static readonly customerSurveys = CustomerSurveys;
  public static readonly onfido = Onfido;
  public static readonly deliveryCountries = DeliveryCountries;
  public static readonly liveCharts = LiveCharts;
  public static readonly trustpilot = Trustpilot;
  public static readonly faq = FAQ;
  public static readonly preciousMetalArticles = PreciousMetalArticles;
  public static readonly leadMagnet = LeadMagnet;
  public static readonly gift = Gift;
  public static readonly cart = Cart;
  public static readonly collections = Collections;
  public static readonly creditCard = CreditCard;
  public static readonly savingAssistant = SavingAssistant;
  public static readonly contact = Contact;
  public static readonly forecastedDelivery = ForecastedDelivery;
  public static readonly widget = Widget;
  public static readonly sanity = Sanity;
}
