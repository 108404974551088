import { Dispatch, FC, memo, SetStateAction, useEffect, useRef, useState } from 'react';
import { Keyframes, useTheme } from 'styled-components';

import { Box, Container, getSlideDownAnimation, getSlideUpAnimation } from '@core/component';
import { util } from '@core/constant';
import {
  useContextCart,
  useContextCurrency,
  useContextHeaderHeight,
  useContextMediaQuery,
  useContextUser,
} from '@core/context';
import { useLanguage, useScrollDirection } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EHeader,
  EIcon,
  ESize,
  EZIndexName,
  ThemeProps,
} from '@core/type';
import { BlogCategory } from '@core/type/api';

import { HeaderBlog } from './HeaderBlog';
import { HeaderMenuBlog } from './HeaderMenuBlog';
import { FullLogoLink } from './components/FullLogoLink';

export const HeaderContainer: FC<{
  type: EHeader;
  isTransform?: boolean;
  setIsMounted?: Dispatch<SetStateAction<boolean>>;
  shouldShowBanner?: boolean;
  blogCategories: BlogCategory[];
}> = ({ type, isTransform, setIsMounted, blogCategories }) => {
  const headerRef = useRef(null);
  const [animation, setAnimation] = useState<Keyframes>();
  const { height, setHeight } = useContextHeaderHeight();
  const [heightAnimation, setHeightAnimation] = useState<string>(height);
  const { mediaQuery, isMobile } = useContextMediaQuery();
  const { isDown, isUp } = useScrollDirection(util.DEBOUNCE);
  const { isLogged, isReady } = useContextUser();
  const { initializeCart } = useContextCart();
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const {
    structure: {
      header: {
        blog: { height: heightBlog, animation: animationBlog, padding: blogPadding },
        maxWidth,
        animation: animationMedia,
        logoHeight,
      },
    },
  } = useTheme() as ThemeProps;

  const triggerSlideAnimation = (heightEnd: string, animation: Keyframes) => {
    setAnimation(animation);
    setHeight(heightEnd);
    setHeightAnimation(heightEnd);
  };

  const unmountElement = () => {
    if (setIsMounted) {
      setIsMounted(true);
    }

    setHeightAnimation(heightBlog);
    setHeight(heightBlog);
  };

  const triggerShortAnimation = (canTransform: boolean) => {
    if (canTransform) {
      triggerSlideAnimation(heightBlog, getSlideDownAnimation(animationMedia));
    } else {
      unmountElement();
    }
  };

  useEffect(() => {
    triggerShortAnimation(isTransform);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, mediaQuery]);

  const isLogoCentered = type === EHeader.DEFAULT && ESize.XL !== mediaQuery;

  useEffect(() => {
    if (isDown) {
      setAnimation(getSlideUpAnimation(animationBlog));
      setHeight(`${parseInt(heightBlog) - parseInt(animationBlog)}px`);
    } else if (isUp) {
      setAnimation(getSlideDownAnimation(animationBlog));
      setHeight(heightBlog);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDown, isUp]);

  useEffect(() => {
    initializeCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isReady, language, currency]);

  return (
    <Box as={EActionAttribute.HEADER} display={'block'} height={heightAnimation} ref={headerRef}>
      <Box position={'fixed'} top={'0'} width={'100%'} height={height} zIndex={EZIndexName.HEADER}>
        <Box
          animation={animation}
          animationDuration={'0.7s'}
          transition={'background-color 700ms linear'}
          transform={isTransform && !isMobile ? `translateY(-${animationBlog})` : ''}
          position={'relative'}
          zIndex={EZIndexName.OVER_DEFAULT}
        >
          <Box
            backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
            padding={blogPadding}
            width={'100%'}
            position={'relative'}
          >
            <Container maxWidth={maxWidth}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexDirection={isLogoCentered ? 'column' : 'row'}
                gap={'10px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  width={isMobile ? '100%' : undefined}
                >
                  <FullLogoLink height={logoHeight} iconType={EIcon.GA_LOGO_FULL_COLOR_FILLED} />
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap="15px"
                  justifyContent={'space-between'}
                  width={isMobile ? '100%' : undefined}
                >
                  <HeaderBlog />
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box
          backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
          width={'100%'}
          animation={animation}
          transform={isTransform && !isMobile ? `translateY(-${animationBlog})` : null}
          animationDuration={'0.7s'}
          transition={'background-color 700ms linear'}
          position={'relative'}
          borderColor={{ commonType: EColor.GRAY, intensity: EColor.A200 }}
          borderWidth={isMobile ? '0 0 1px 0' : '1px 0 1px 0'}
          borderStyle={'solid'}
        >
          <Container maxWidth={maxWidth}>
            <HeaderMenuBlog blogCategories={blogCategories} />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export const HeaderBlogDesktop = memo(HeaderContainer);
