import { HTMLAttributeAnchorTarget, ReactNode } from 'react';

import {
  ColorProps,
  EIcon,
  EPosition,
  ERouting,
  IconPosition,
  PositionType,
  StyleRuleWidth,
  TextColorType,
} from '@core/type';

export type DrawerPosition = keyof PositionType<
  EPosition.TOP | EPosition.RIGHT | EPosition.BOTTOM | EPosition.LEFT
>;

export enum EDrawerLevel {
  DRAWER = 'drawer',
  CURRENCY = 'currency',
  LANGUAGE = 'language',
  SHOP = 'shop',
  CHARTS = 'charts',
  RESOURCES = 'resources',
  USER = 'user',
}

export type DrawerCloseProps = {
  closeDrawer?: () => void;
  drawerLevel?: EDrawerLevel | `${EDrawerLevel}/${string}`;
  drawerLevelProps?: Partial<Record<EDrawerLevel | `${EDrawerLevel}/${string}`, boolean>>;
  handleDrawerLevelProps?: (props: Partial<Record<EDrawerLevel, boolean>>) => void;
};

export type DrawerProps = DrawerCloseProps & {
  children?: ReactNode;
  position: DrawerPosition;
  backgroundColor?: string;
  size?: string;
  iconOrientation?: IconPosition;
  iconWidth?: StyleRuleWidth;
  iconColor?: ColorProps<TextColorType>;
};

export type SideNavDrawerLineProps = DrawerCloseProps & {
  route: ERouting;
  title: string;
  icon: EIcon;
  hasError?: boolean;
};

export type DrawerLineProps = DrawerCloseProps & {
  children?: ReactNode;
  title: string | ReactNode;
  titleContent?: ReactNode;
  route?: ERouting;
  query?: NodeJS.Dict<string | string[]>;
  isInvalid?: boolean;
  target?: HTMLAttributeAnchorTarget;
  href?: string;
  hasIcon?: boolean;
  isBlogPage?: boolean;
  iconType?: EIcon;
  trackEvent?: () => void;
};

export type SubDrawerProps = DrawerCloseProps & {
  children?: ReactNode;
  position: DrawerPosition;
  backgroundColor?: string;
  size?: string;
  title?: ReactNode | string;
  titleContent?: ReactNode;
  listTitle?: ReactNode;
  hasIcon?: boolean;
  isBlogPage?: boolean;
  trackEvent?: () => void;
};

export type DrawerPositionProps = {
  [key in DrawerPosition]: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
};

export type DrawerSubContentProps = {
  isOpen: boolean;
  position: DrawerPosition;
  size?: string;
  backgroundColor?: string;
};

export type DrawerContentProps = DrawerSubContentProps & {
  height: number;
  isSubDrawerOpen?: boolean;
};
