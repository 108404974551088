import { ParsedUrlQuery } from 'querystring';

import { slugToMetal, slugToMetalPrice } from '@core/constant';
import { EHeader, ELanguage, ELanguageTags, EMetalSlug, ERouting } from '@core/type';
import { ESanityDocument, ESanityFactory, ESanityField, ESanityVariant } from '@core/type/sanity';
import { getLanguageFromTags } from '@core/util';

export const getSanityQuery = (cms: ERouting, query: ParsedUrlQuery, language: ELanguageTags) => {
  switch (cms) {
    case ERouting.HOME:
      return `*[_type == "${cms}" && ${ESanityField.LANGUAGE} == "${getLanguageFromTags(
        language,
      )}"]{...,_id, "${ESanityField.AB_TEST}": *[_type == "${
        ESanityDocument.AB_TEST
      }" && references(^._id)]{"variant": ${ESanityVariant.HOME}, key}[0]}[0]`;
    case ERouting.PAGES:
      return `*[_type == "${cms}" && ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current == "${
        query.slug as string
      }" && language == "${getLanguageFromTags(language)}"]{...,_id, "${
        ESanityField.AB_TEST
      }": *[_type == "${ESanityDocument.AB_TEST}" && references(^._id)]{"variant": ${
        ESanityVariant.PAGES
      }, key}[0],
    "alternates": *[_type == "${
      ESanityDocument.TRANSLATION_METADATA
    }" && references(^._id)]{'alt': translations[].value->{"${ESanityField.SLUG}": ${
      ESanityFactory.PAGE_SLUG
    }.${ESanityField.SLUG}.current, ${ESanityField.LANGUAGE}} }[0] }[0]`;
    case ERouting.CHARTS:
      return `*[_type == "${cms}" && ${ESanityField.METAL_ISO} == "${
        slugToMetalPrice[language][query.metalSlug as EMetalSlug]
      }" && ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current == "${
        Array.isArray(query.slug) ? query.slug.join('/') : query.slug
      }" && ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"]{...,_id, "${
        ESanityField.AB_TEST
      }": *[_type == "${ESanityDocument.AB_TEST}" && references(^._id)]{"variant": ${
        ESanityVariant.CHARTS
      }, key}[0],
        "alternates": *[_type == "${
          ESanityDocument.TRANSLATION_METADATA
        }" && references(^._id)]{'alt': translations[].value->{"${ESanityField.SLUG}": ${
          ESanityFactory.PAGE_SLUG
        }.${ESanityField.SLUG}.current, ${ESanityField.LANGUAGE}, ${
          ESanityField.METAL_ISO
        } } }[0] }[0]`;
    case ERouting.BUY:
      return `*[_type == "${cms}" && ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current == "${
        query.metal as string
      }" && ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"][0] {...,
        "alternates": *[_type == "translation.metadata" && references(^._id)]{'alt': translations[].value->{"${
          ESanityField.SLUG
        }": ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current, ${
          ESanityField.LANGUAGE
        }}}[0]}`;
    case ERouting.HOME_BUY:
      return `*[_type == "${cms}" && ${ESanityField.LANGUAGE} == "${getLanguageFromTags(
        language,
      )}"][0]`;
    case ERouting.SHOP_SUBCATEGORY:
      return `*[_type == "${cms}" && ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current == "${
        query.slug as string
      }" && ${ESanityField.METAL_ISO} == "${slugToMetal[language][query.metal as EMetalSlug]}" && ${
        ESanityField.LANGUAGE
      } == "${getLanguageFromTags(language)}"][0] {...,
        "alternates": *[_type == "${
          ESanityDocument.TRANSLATION_METADATA
        }" && references(^._id)]{'alt': translations[].value->{"${ESanityField.SLUG}": ${
          ESanityFactory.PAGE_SLUG
        }.${ESanityField.SLUG}.current, ${ESanityField.LANGUAGE},
           ${ESanityField.METAL_ISO}}}[0]}`;
    case ERouting.PRODUCT_SHOW:
      /* return `*[_type == "${cms}" && slug.current == "${query.slug as string}" && metalIso == "${
        slugToMetal[language][query.metal as EMetalSlug] as string
      }" && ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"][0] {...,
        "alternates": *[_type == "translation.metadata" && references(^._id)]{'alt': translations[].value->{"${
          ESanityField.SLUG
        }": ${ESanityField.SLUG}.current, ${ESanityField.LANGUAGE},
           ${ESanityField.METAL}}}[0]}`;*/
      return `*[_type == "${cms}" && ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current == "${
        query.slug as string
      }" && metalIso == "${slugToMetal[language][query.metal as EMetalSlug] as string}" && ${
        ESanityField.LANGUAGE
      } == "${getLanguageFromTags(language)}"][0] {...,
        "alternates": *[_type == "translation.metadata" && references(^._id)]{'alt': translations[].value->{"${
          ESanityField.SLUG
        }": ${ESanityFactory.PAGE_SLUG}.${ESanityField.SLUG}.current, ${ESanityField.LANGUAGE},
           ${ESanityField.METAL_ISO}}}[0]}`;
    default:
      return `*[_type == "${cms}" ${Object.keys(query).reduce((previous, current) => {
        return `${previous} && ${current} == "${query[current] as string}"`;
      }, '')} && ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"][0]`;
  }
};

export const getSanityDefaultHeaderMenus = (language: ELanguage) => {
  return `*[_type == "${ESanityDocument.HEADER}" && ${ESanityField.HEADER_VARIANT} == "${EHeader.DEFAULT}" && ${ESanityField.LANGUAGE} == "${language}" && _id match "drafts*" == false][0]{ "menus": body.menus }`;
};

export const getSanityRedirection = (
  cms: ERouting,
  query: ParsedUrlQuery,
  language: ELanguageTags,
) => {
  switch (cms) {
    case ERouting.PAGES:
      return `*[_type == "${cms}" &&
      "${query.slug as string}" in pageSlug.history &&
      language == "${getLanguageFromTags(language)}"][0]`;
    case ERouting.CHARTS:
      return `*[_type == "${cms}" &&
      ${ESanityField.METAL_ISO} == "${slugToMetalPrice[language][query.metalSlug as EMetalSlug]}" &&
      "${Array.isArray(query.slug) ? query.slug.join('/') : query.slug}" in pageSlug.history &&
      ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"][0]`;
    case ERouting.BUY:
      return `*[_type == "${cms}" &&
      "${query.metal as string}" in pageSlug.history &&
      ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"][0]`;
    case ERouting.SHOP_SUBCATEGORY:
      return `*[_type == "${cms}" &&
      "${query.slug as string}" in pageSlug.history &&
      ${ESanityField.METAL_ISO} == "${slugToMetal[language][query.metal as EMetalSlug]}" &&
      ${ESanityField.LANGUAGE} == "${getLanguageFromTags(language)}"][0]`;
    default:
      return null;
  }
};
