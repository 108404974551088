import styled, { CSSObject, css, keyframes } from 'styled-components';

import { EColor, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { Box } from '../layout';
import { ELoaderAnimation, SkeletonBoxProps } from './interface-skeleton';

const pulseAnimation = keyframes`
  0%, 100% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }
`;

const waveAnimation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

export const SkeletonWrapper = styled(Box)<SkeletonBoxProps>((
  props: SkeletonBoxProps & WithThemeProps,
) => {
  const {
    theme: { palette },
    loaderAnimation = ELoaderAnimation.PULSE,
    borderRadius,
    boxShadow: boxShadowProps,
    animationDuration = '1.5s',
    animationIterationCount = 'infinite',
    animationTimingFunction = 'linear',
    backgroundColor,
    ...restProps
  } = props;
  const { getBoxShadow, getBackgroundColor } = getPaletteHandlers(palette);
  const boxShadow = getBoxShadow(boxShadowProps);
  const gray = getBackgroundColor(
    backgroundColor || { commonType: EColor.GRAY, intensity: EColor.R100 },
  );
  const white = getBackgroundColor({ commonType: EColor.GRAY, intensity: EColor.A800 });

  const animation = loaderAnimation === ELoaderAnimation.PULSE ? pulseAnimation : waveAnimation;

  return css`
    overflow: hidden;
    ${restProps as unknown as CSSObject}

    p, span, a, li, button, img {
      animation: ${animation} ${animationDuration} ${animationTimingFunction}
        ${animationIterationCount};
      ${loaderAnimation === ELoaderAnimation.PULSE
        ? `background-color: ${gray};`
        : `background: linear-gradient(110deg, ${gray} 8%, ${white} 18%, ${gray} 33%);
        background-size: 200% 100%;`}
      box-shadow: ${boxShadow};
      border-radius: ${borderRadius};
    }
  `;
});
