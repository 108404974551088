export enum ELayout {
  DEFAULT = 'headerDefault',
  BLOG = 'blog',
  SHORT = 'headerShort',
  LANDING = 'landing',
}

export enum EFooter {
  DEFAULT = 'default',
  LOGOS = 'logos',
}
