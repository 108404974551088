import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Box } from '@core/component';
import { HEADER_TRANSITION, util } from '@core/constant';
import {
  useContextCart,
  useContextCurrency,
  useContextHeaderHeight,
  useContextMediaQuery,
  useContextUser,
} from '@core/context';
import { useLanguage, useScrollDirection } from '@core/hook';
import { EActionAttribute, EHeader, EZIndexName } from '@core/type';
import { ConnectedBannerContainer, useConnectedBanner } from '@core/widget';

import { HeaderBlogDesktop } from './HeaderBlogDesktop';
import { HeaderDefault, HeaderShort } from './components';
import { HeaderContainerProps } from './interface-header';
import {
  getHeaderContainerHeight,
  getHeaderContainerMobileHeight,
  getHeaderMobileTranslateY,
  getHeaderTranslateY,
} from './utils';

export const HeaderContainer = ({
  subcategories,
  shouldShowCurrencyLink,
  menus,
  shouldShowBanner,
  header,
  headerType = EHeader.DEFAULT,
  blogCategories,
  isTransform,
  setIsMounted,
}: HeaderContainerProps) => {
  const [headerTranslateY, setHeaderTranslateY] = useState<string>(`translateY(0px)`);

  const { initializeCart } = useContextCart();
  const { isLogged, isReady } = useContextUser();

  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const { isDown, isUp } = useScrollDirection(util.DEBOUNCE);
  const { isOpen, bannerData, ...restBannerProps } = useConnectedBanner();
  const { isMobile } = useContextMediaQuery();

  const isBannerOpen = shouldShowBanner && isOpen && Boolean(bannerData);

  const [headerContainerHeight, setHeaderContainerHeight] = useState<string>(
    getHeaderContainerHeight(isBannerOpen, headerType),
  );

  const { setHeight } = useContextHeaderHeight();

  useEffect(() => {
    if (isMobile) {
      setHeaderContainerHeight(getHeaderContainerMobileHeight(isBannerOpen, headerType));

      if (isDown) {
        setHeaderTranslateY(getHeaderMobileTranslateY(headerType, isBannerOpen));
        setHeight('0px');
      } else if (isUp) {
        setHeaderTranslateY('translateY(0px)');
        setHeight(headerContainerHeight);
      }
    } else {
      setHeaderContainerHeight(getHeaderContainerHeight(isBannerOpen, headerType));

      if (isDown) {
        setHeaderTranslateY(getHeaderTranslateY(headerType, isBannerOpen));
      } else if (isUp) {
        setHeaderTranslateY('translateY(0px)');
      }
    }
  }, [isMobile, headerType, isDown, isUp, isBannerOpen]);

  useEffect(() => {
    initializeCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, isReady, language, currency]);

  const headers = {
    [EHeader.SHORT]: (
      <HeaderShort
        hasLine={!header?.hasProgressBar}
        shouldShowCurrencyLink={shouldShowCurrencyLink}
      />
    ),
    [EHeader.DEFAULT]: (
      <HeaderDefault
        shouldShowCurrencyLink={shouldShowCurrencyLink}
        subcategories={subcategories}
        menus={menus}
        isBlogPage={false}
      />
    ),
    [EHeader.BLOG]: (
      <HeaderDefault
        shouldShowCurrencyLink={shouldShowCurrencyLink}
        subcategories={subcategories}
        menus={menus}
        isBlogPage
      />
    ),
    [EHeader.LANDING]: <></>,
  };

  return (
    <>
      {headerType === EHeader.BLOG && !isMobile ? (
        <HeaderBlogDesktop
          type={EHeader.BLOG}
          isTransform={isTransform}
          setIsMounted={setIsMounted}
          shouldShowBanner={shouldShowBanner}
          blogCategories={blogCategories}
        />
      ) : (
        <Box
          as={EActionAttribute.HEADER}
          position={'relative'}
          zIndex={EZIndexName.HEADER}
          height={headerContainerHeight}
          transition={`height ${HEADER_TRANSITION}`}
        >
          <StyledHeaderContainer transform={headerTranslateY}>
            {isBannerOpen && (
              <ConnectedBannerContainer bannerData={bannerData} {...restBannerProps} />
            )}

            {headers[headerType]}
          </StyledHeaderContainer>
        </Box>
      )}
    </>
  );
};

const StyledHeaderContainer = styled(Box)`
  position: fixed;
  transition: transform ${HEADER_TRANSITION};
  left: 0;
  right: 0;
  top: 0;
`;
