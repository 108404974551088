import { Box, Line } from '@core/component';
import { useContextUser } from '@core/context';

import { CloseDrawerFnTyp } from '../../../../interface-header';
import { ButtonSignIn, ButtonSignOut } from '../../button';
import { NeedHelp } from '../NeedHelp';

export const SideNavFooter = ({ closeDrawer }: CloseDrawerFnTyp) => {
  const { isLogged } = useContextUser();

  return (
    <>
      <Line margin={'48px 16px'} />
      <NeedHelp closeDrawer={closeDrawer} />
      <Box padding={'0 40px'}>
        {isLogged ? (
          <ButtonSignOut closeDrawer={closeDrawer} />
        ) : (
          <ButtonSignIn closeDrawer={closeDrawer} />
        )}
      </Box>
    </>
  );
};
