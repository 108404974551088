import type { AxiosInstance, AxiosResponse } from 'axios';

import { LanguageProps } from '@core/type';
import type { ProductBlockEntity, ProductPageEntity, SearchProductEntity } from '@core/type/api';
import { PagedRequest, ProductAutoSavingsEntity } from '@core/type/api';

import { Api } from '../constants/api';
import { replaceIdUrl } from '../helpers/api';
import { configResponse, EConfigName } from '../helpers/config';
import { addParameters } from '../helpers/util';

const searchProducts = async (
  axiosInstance: AxiosInstance,
  {
    searchTerm,
    language,
    page,
    perPage,
  }: LanguageProps &
    PagedRequest & {
      searchTerm: string;
    },
): Promise<AxiosResponse<SearchProductEntity>> => {
  const parameters: Record<string, string> = {
    q: searchTerm,
  };

  if (page) {
    parameters.page = page.toString();
  }

  if (perPage) {
    parameters.per_page = perPage.toString();
  }

  return axiosInstance.get(
    addParameters(Api.product.SEARCH, parameters),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );
};

const getProductBySlug = async (
  axiosInstance: AxiosInstance,
  { slug, language }: LanguageProps & { slug: string },
): Promise<AxiosResponse<ProductPageEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.product.PRODUCT_BY_SLUG, slug, ':slug'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getProductById = async (
  axiosInstance: AxiosInstance,
  { id, language }: LanguageProps & { id: string },
): Promise<AxiosResponse<ProductPageEntity>> =>
  axiosInstance.get(
    replaceIdUrl(Api.product.PRODUCT_BY_ID, id, ':id'),
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getProductSilverLP = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductBlockEntity>> =>
  axiosInstance.get(
    Api.product.PRODUCT_SILVER_LP,
    configResponse({ [EConfigName.LANGUAGE]: language }),
  );

const getAutoSavingsProducts = async (
  axiosInstance: AxiosInstance,
  { language }: LanguageProps,
): Promise<AxiosResponse<ProductAutoSavingsEntity>> =>
  axiosInstance.get(Api.product.AUTO_SAVINGS, configResponse({ [EConfigName.LANGUAGE]: language }));

export class ProductAPI {
  public static readonly searchProducts = searchProducts;
  public static readonly getProductBySlug = getProductBySlug;
  public static readonly getProductById = getProductById;
  public static readonly getProductSilverLP = getProductSilverLP;
  public static readonly getAutoSavingsProducts = getAutoSavingsProducts;
}
