import { memo } from 'react';
import styled, { css } from 'styled-components';

import { zIndexes } from '@core/constant';
import { EColor, EIcon, EPosition, ESize, ETypographyVariant, EZIndexName } from '@core/type';

import { Icon } from '../icon';
import { Box } from '../layout';
import { Typography } from '../typography';
import { placements, transforms } from './Drawer';
import { DrawerSubContentProps, SubDrawerProps } from './interface-drawer';

export const DrawerSub = memo(
  ({
    children,
    position,
    backgroundColor,
    size,
    listTitle,
    title,
    titleContent,
    closeDrawer,
    drawerLevel,
    drawerLevelProps,
    handleDrawerLevelProps,
    hasIcon = true,
    isBlogPage,
    trackEvent,
  }: SubDrawerProps) => {
    const isRightMenu = position === EPosition.RIGHT;

    const openMenuDrawer = () => {
      handleDrawerLevelProps({ [drawerLevel]: true });
      trackEvent?.();
    };

    return (
      <>
        {titleContent ? (
          <Box role={'button'} cursor={'pointer'} onClick={openMenuDrawer}>
            {titleContent}
          </Box>
        ) : (
          <Box
            role={'button'}
            cursor={'pointer'}
            onClick={openMenuDrawer}
            display={'flex'}
            gap={'16px'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {listTitle || <Typography>{title}</Typography>}
            {hasIcon && (
              <Icon
                type={EIcon.ANGLE}
                orientation={EPosition.RIGHT}
                size={ESize.MD}
                flexShrink={'0'}
                margin={'-1px 0'}
              />
            )}
          </Box>
        )}

        <DrawerContent
          isOpen={drawerLevelProps[drawerLevel]}
          size={size}
          position={position}
          backgroundColor={backgroundColor}
        >
          <Box
            display={'flex'}
            height={'62px'}
            padding={'4px 20px'}
            alignItems={'center'}
            justifyContent={isBlogPage ? 'flex-end' : 'flex-start'}
          >
            <Icon
              display={'inline-flex'}
              type={EIcon.CROSS}
              size={ESize.SM}
              color={EColor.SECONDARY}
              onClick={closeDrawer}
            />
          </Box>

          <Box
            padding="32px 20px 32px 40px"
            lineHeight="24px"
            onClick={() => handleDrawerLevelProps({ [drawerLevel]: false })}
          >
            <Box
              display={'flex'}
              gap={'16px'}
              flexDirection={isRightMenu ? 'row-reverse' : 'row'}
              justifyContent={isRightMenu ? 'space-between' : 'flex-start'}
            >
              <Icon type={EIcon.ANGLE} orientation={position} size={ESize.MD} strokeWidth={2} />
              <Typography variant={ETypographyVariant.H6}>{title}</Typography>
            </Box>
          </Box>
          {children}
        </DrawerContent>
      </>
    );
  },
);
DrawerSub.displayName = 'DrawerSub';

const DrawerContent = styled.nav<DrawerSubContentProps>(
  ({ isOpen, position, size, backgroundColor }: DrawerSubContentProps) => {
    return css`
      display: block;
      box-sizing: border-box;
      position: fixed;
      ${placements[position]}
      z-index: ${zIndexes[EZIndexName.TOOLTIP]};
      width: ${position !== EPosition.TOP && position !== EPosition.BOTTOM && size
        ? size
        : '300px'};
      transform: ${!isOpen ? transforms[position] : 'translateX(0)'};
      transition: transform 0.2s;
      overflow: auto;
      background-color: ${backgroundColor || '#ffffff'};
      ${position === EPosition.LEFT && 'box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.19);'}
      flex-direction: column;
      backface-visibility: hidden;
      margin: 0;
      padding-left: 0;
      padding-bottom: 24px;
      list-style-type: none;
      height: 100vh;
    `;
  },
);
