export const toastProperties = {
  // in millisecond
  duration: 9000,
};

export const componentNaming = {
  DISCOUNT_BANNER: 'Banner - Public Discount',
  BLOG_ARTICLE: 'Blog Articles',
  BUTTON_GROUP: 'Buttons',
  CAROUSEL_BANNER: 'Carousel - Banners',
  CAROUSEL_PRODUCTS: 'Carousel - Products',
  CAROUSEL_TRUSTPILOT: 'Carousel - Trustpilot',
  CAROUSEL_SUBCATEGORIES: 'Carousel - Subcategories',
  FAQ: 'FAQs - Homepage',
  GRID: 'Grid',
  GUIDE: 'Guides',
  ARTICLES: 'Homepage - Articles (static)',
  INVESTMENT_ASSISTANT: 'Homepage - Investment Assistant (static)',
  PRODUCTS: 'Homepage - Products (static)',
  SECURE_SAVING: 'Homepage - Secure Savings (static)',
  IMAGE: 'Image',
  CHARTS: 'Live Charts',
  CHARTS_INVESTMENT: 'Investment Charts',
  TABLE_SPORT_PRICE_DAILY_STAT:
    'Live Charts - Table Daily Performances (Currencies, Weights, Carats)',
  TABLE_SPORT_PRICE_HISTORY: 'Live Charts - Table History Performances (Metal)',
  MARGIN: 'Margins',
  RICH_TEXT: 'Rich Text',
  TEXT: 'Text',
  TEXT_EDITOR: 'Text Editor',
  PAGE_RATING_STARS: 'Page rating stars',
  RATING_STARS: 'Rating stars',
  CONTAINER: 'Container',
  CHART_TRADING: 'Chart Trading',
  INVESTMENT_ASSISTANT_BANNER: 'Investment assistant banner',
  VIDEO: 'Video',
  TWEET: 'Tweet',
  PRODUCT_TABS: 'Product Tabs',
};
