import { Box, BoxProps, Icon } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, EIcon, ESize } from '@core/type';

export const FooterWithLogos = ({
  iconContainerProps,
  ...restProps
}: {
  iconContainerProps?: BoxProps;
} & BoxProps) => {
  const { isPhone, mediaQuery } = useContextMediaQuery();

  return (
    <Box
      width="100%"
      backgroundColor={EColor.SECONDARY}
      as={EActionAttribute.FOOTER}
      padding={'24px 20px'}
      marginTop={'auto'}
      {...restProps}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={isPhone ? 'center' : 'space-between'}
        flexWrap={'wrap'}
        margin={'0 auto'}
        gap={'24px 48px'}
        maxWidth={mediaQuery === ESize.XS ? '300px' : '600px'}
        {...iconContainerProps}
      >
        <Icon type={EIcon.TRUSTPILOT} height="26px" width={'70px'} flexShrink={0} />
        <Icon type={EIcon.LOGO_60_YEARS} height="34px" width={'93px'} flexShrink={0} />
        <Icon type={EIcon.LOGO_MAZARS} height="23px" width={'77px'} flexShrink={0} />
        <Icon type={EIcon.LOGO_SWISS} height="25px" width={'77px'} flexShrink={0} />
      </Box>
    </Box>
  );
};
