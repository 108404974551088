import type { AxiosInstance, AxiosResponse } from 'axios';

import { ELanguageTags } from '@core/type';
import type {
  ProductAutoSavingsEntity,
  ProductBlockEntity,
  ProductPageEntity,
  SearchProductEntity,
} from '@core/type/api';

import { ProductAPI } from '../api/product';
import { logService } from '../helpers/util';

const log = async <T, R>(
  methodName: string,
  dto: T & { axiosInstance: AxiosInstance },
  apiCall: (axiosInstance: AxiosInstance, obj: T) => Promise<AxiosResponse<R>>,
  withContent?: boolean,
): Promise<R> => {
  return logService('SearchProductsService', methodName, dto, apiCall, withContent);
};

const searchProducts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  searchTerm: string,
  page?: number,
  perPage?: number,
): Promise<SearchProductEntity> => {
  return log(
    'searchProducts',
    { axiosInstance, searchTerm, language, page, perPage },
    ProductAPI.searchProducts,
  );
};

const getProductBySlug = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  slug: string,
): Promise<ProductPageEntity> => {
  return log('getProductBySlug', { axiosInstance, language, slug }, ProductAPI.getProductBySlug);
};

const getProductById = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  id: string,
): Promise<ProductPageEntity> => {
  return log('getProductById', { axiosInstance, language, id }, ProductAPI.getProductById);
};

const getProductSilverLP = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductBlockEntity> => {
  return log('getProductSilverLP', { axiosInstance, language }, ProductAPI.getProductSilverLP);
};

const getAutoSavingsProducts = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<ProductAutoSavingsEntity> => {
  return log(
    'getAutoSavingsProducts',
    { axiosInstance, language },
    ProductAPI.getAutoSavingsProducts,
  );
};

export class ProductService {
  public static readonly searchProducts = searchProducts;
  public static readonly getProductBySlug = getProductBySlug;
  public static readonly getProductById = getProductById;
  public static readonly getProductSilverLP = getProductSilverLP;
  public static readonly getAutoSavingsProducts = getAutoSavingsProducts;
}
