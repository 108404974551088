import { ECurrency } from './currency';
import { EGTMKYCLimit, EGTMLoginStatus } from './google-tag-manager';
import { ELanguage } from './language';
import { EFullMetalName } from './metal';
import { EFullProductType } from './product-type';
import { EUserType } from './profile';
import { EShopQuery } from './shop';
import { Price } from './unit';

export enum EMixpanelEventName {
  PAGE_VIEWED = 'Page viewed',
  CART_VIEWED = 'Cart viewed',
  PRODUCT_ADDED_TO_CART = 'Product Added to Cart',
  PRODUCT_REMOVED_FROM_CART = 'Product Removed from Cart',
  KYC_FLOW_PROGRESSED = 'KYC Flow Progressed',
  CAROUSEL_PRODUCTS = 'Carousel_Products',
  CAROUSEL_BANNER = 'Carousel_banner',
  ACCOUNT_CREATION_DONE_PAGE = 'Account creation Done page',
  AB_TEST = '$experiment_started',
  INVESTMENT_CHARTS_STRATEGIES = 'I had invested 5 years ago strategies',
  INVESTMENT_CHARTS_USAGE = 'I had invested 5 years ago usage',
  SAVINGS_ASSISTANT_USED = 'Savings Assistant Used',
  HEADER_INTERACTION = 'Header Interaction',
  FILTERS_INTERACTION = 'Filters_interaction',
}

export enum EMixpanelPackageSelected {
  COIN = 'Coin',
  FLEXIBLE = 'Flexible',
  BEST_VALUE = 'Best value',
}

export enum EMixpanelStepName {
  PURCHASING_LIMIT = 'Purchasing limit',
  DONE = 'Done',
}

export enum EMixpanelEnv {
  WEB_APP = 'web_app',
}

export enum EMixpanelAccountCreationDoneCtasName {
  SKIP_FOR_NOW = 'Skip for now',
  VERIFY_MY_ACCOUNT = 'Verify my account',
}

export enum EMixpanelHeaderSectionType {
  MENU = 'Menu',
  SUB_MENU = 'Submenu',
  LINK = 'Link',
}

export type MixpanelEventProps = {
  language: ELanguage;
  currency: ECurrency;
  environment: EMixpanelEnv;
  loginStatus?: EGTMLoginStatus;
  productId?: string;
  productCategory?: EFullMetalName;
  productName?: string;
  productPrice?: Price;
  productType?: EFullProductType;
  productWeight?: string;
  productQuantity?: number;
  productGroup?: string;
  packageSelected?: EMixpanelPackageSelected;
  cartSize?: number;
  cartValue?: Price;
  productsCategories?: EFullMetalName[];
  productsIds?: number[];
  productsNames?: string[];
  productsPrices?: Price[];
  stepName?: EMixpanelStepName;
  stepNumber?: number;
  KYCPurchasingLimit?: EGTMKYCLimit;
  imageId?: number;
  userGaPayUsed?: boolean;
  abTestKey?: string;
  isVariant?: boolean;
  accountCreationDoneCtasName?: EMixpanelAccountCreationDoneCtasName;
  metal?: EFullMetalName;
  initialInvestment?: Price;
  monthlyInvestment?: Price;
  assistantMetal?: EFullMetalName;
  assistantBudget?: Price;
  sectionType?: string;
  sectionName?: string;
  urlPath?: string;
  userType?: EUserType;
  filterType?: EShopQuery;
  filterName?: string;
};

export type MixpanelHeaderEventProps = {
  sectionType?: string;
  sectionName?: string;
  urlPath?: string;
};
