import { useEventMixpanel } from '@mixpanel';

import { LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { usePageLoading } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EMixpanelHeaderSectionType,
  HeaderMenuShortDropdownProps,
} from '@core/type';

import { MenuItemContainer } from './MenuItemContainer';
import { HoveredMenuHandler } from './interface-header-menu';
import { getHeaderLinkHref } from './utils';

export const MenuShortDropdown = ({
  link,
  links,
  onHover,
  index,
}: HeaderMenuShortDropdownProps & {
  onHover: HoveredMenuHandler;
  index: number;
}) => {
  const { trackHeaderEvents } = useEventMixpanel();
  const { isLoading, startLoading } = usePageLoading();
  const href = getHeaderLinkHref(link);

  const handleClick = () => {
    startLoading();
    trackHeaderEvents({
      sectionName: link.text,
      sectionType: EMixpanelHeaderSectionType.MENU,
      urlPath: href,
    });
  };

  return (
    <MenuItemContainer onHover={onHover} index={index} shouldShowBorder={links.length > 0}>
      {href ? (
        <LinkTranslatedStyledAsLink
          as={EActionAttribute.LINK}
          display={'flex'}
          alignItems={'center'}
          href={href}
          isUnderlinedHover={false}
          color={isLoading ? EColor.ACCENT : undefined}
          onClick={handleClick}
        >
          <Typography fontWeight={EFontWeight.BOLD} whiteSpace={'nowrap'}>
            {link.text}
          </Typography>
        </LinkTranslatedStyledAsLink>
      ) : (
        <Typography fontWeight={EFontWeight.BOLD} whiteSpace={'nowrap'}>
          {link.text}
        </Typography>
      )}
    </MenuItemContainer>
  );
};
