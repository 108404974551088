import { Box } from '@core/component';
import { EColor, EIcon, EPosition } from '@core/type';

import { FullLogoLink } from '../../FullLogoLink';
import { ButtonCart } from '../button';
import { HeaderDefaultMobileProps } from '../interface-header-default';
import { SideNav } from './SideNav';

export const HeaderDefaultMobile = ({
  subcategories,
  shouldShowCurrencyLink,
  isBlogPage,
  menus,
}: HeaderDefaultMobileProps) => (
  <Box
    padding={'14px 0'}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'space-between'}
    flexDirection={isBlogPage ? 'row-reverse' : 'row'}
    backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
  >
    <SideNav
      shouldShowCurrencyLink={shouldShowCurrencyLink}
      subcategories={subcategories}
      iconColor={EColor.PRIMARY}
      iconWidth={'24px'}
      isBlogPage={isBlogPage}
      iconOrientation={isBlogPage ? EPosition.TOP : EPosition.BOTTOM}
      menus={menus}
    />
    <Box flexShrink={'0'}>
      <FullLogoLink height={'30px'} iconType={EIcon.GA_LOGO_FULL_COLOR_FILLED} />
    </Box>
    {!isBlogPage && <ButtonCart />}
  </Box>
);
