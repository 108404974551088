import { useEventMixpanel } from '@mixpanel';

import { Box, LinkTranslatedStyledAsLink, Typography } from '@core/component';
import {
  EColor,
  EFontWeight,
  EHeaderMenu,
  EMixpanelHeaderSectionType,
  EZIndexName,
  HeaderMenuFullwidthDropdownProps,
  HeaderMenuShortDropdownProps,
  MixpanelHeaderEventProps,
} from '@core/type';

import { MenuDropdownProps } from './interface-header-menu';
import { getHeaderLinkHref } from './utils';

export const Dropdown = ({
  index: currentIndex,
  hoveredMenuProps: { styleProps, index },
  menu: menuProps,
  onClickLink,
}: MenuDropdownProps) => {
  const { trackHeaderEvents } = useEventMixpanel();

  const isDropdownShort = menuProps.menuType === EHeaderMenu.SHORT_DROPDOWN;

  const left = isDropdownShort ? `${styleProps?.left}px` : '0';
  const width = isDropdownShort ? `${styleProps?.width}px` : '100vw';
  const isVisible = currentIndex === index;

  const handleClick = (eventProps: MixpanelHeaderEventProps) => {
    onClickLink();
    trackHeaderEvents(eventProps);
  };

  return (
    <Box
      opacity={isVisible ? '1' : '0'}
      pointerEvents={isVisible ? undefined : 'none'}
      boxShadow={EColor.ELEVATION_4}
      backgroundColor={{ commonType: EColor.WHITE, intensity: EColor.R900 }}
      padding={'16px 20px 18px'}
      borderRadius={'0 0 10px 10px'}
      minWidth={'164px'}
      position={'absolute'}
      top="53px"
      width={width}
      left={left}
      zIndex={EZIndexName.DROPDOWN}
      transition={'0.3s'}
    >
      {menuProps.menuType === EHeaderMenu.FULLWIDTH_DROPDOWN ? (
        <Box display={'flex'} gap={'80px'} justifyContent={'center'}>
          {(menuProps as HeaderMenuFullwidthDropdownProps).subMenus?.map(
            ({ link, links }, index) => {
              const href = getHeaderLinkHref(link);

              return (
                <Box
                  key={`${link._key}/${index}`}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'8px'}
                >
                  {href ? (
                    <Typography>
                      <LinkTranslatedStyledAsLink
                        fontWeight={EFontWeight.BOLD}
                        href={href}
                        onClick={() =>
                          handleClick({
                            sectionName: link.text,
                            sectionType: EMixpanelHeaderSectionType.SUB_MENU,
                            urlPath: href,
                          })
                        }
                        hoverColor={EColor.SECONDARY}
                      >
                        {link.text}
                      </LinkTranslatedStyledAsLink>
                    </Typography>
                  ) : (
                    <Typography fontWeight={EFontWeight.BOLD}>{link.text}</Typography>
                  )}
                  {links?.length > 0 && (
                    <Box marginTop={'8px'}>
                      {links.map((link) => {
                        const subMenuLinksHref = getHeaderLinkHref(link);

                        return (
                          <Box key={link._key} marginTop="5px">
                            <LinkTranslatedStyledAsLink
                              href={subMenuLinksHref}
                              onClick={() =>
                                handleClick({
                                  sectionName: link.text,
                                  sectionType: EMixpanelHeaderSectionType.LINK,
                                  urlPath: subMenuLinksHref,
                                })
                              }
                              hoverColor={EColor.SECONDARY}
                            >
                              {link.text}
                            </LinkTranslatedStyledAsLink>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              );
            },
          )}
        </Box>
      ) : (
        <Box display={'flex'} flexDirection={'column'} gap={'22px'}>
          {(menuProps as HeaderMenuShortDropdownProps).links?.map((link) => {
            const href = getHeaderLinkHref(link);

            return (
              <Box key={link._key}>
                <LinkTranslatedStyledAsLink
                  href={href}
                  onClick={() =>
                    handleClick({
                      sectionName: link.text,
                      sectionType: EMixpanelHeaderSectionType.LINK,
                      urlPath: href,
                    })
                  }
                  hoverColor={EColor.SECONDARY}
                >
                  {link.text}
                </LinkTranslatedStyledAsLink>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
