import {
  ONFIDO_ASSETS_URL,
  ONFIDO_SDK_URL,
  crisp,
  externalAnalytics,
  google,
  vercel,
} from '@core/constant';

import { EnvironmentPublicNext } from '../../helpers/environment';

export const getStyleSrc = (publicEnvironment: Partial<EnvironmentPublicNext>): string[] => {
  const { host, env, cdnCsp, enableGoogleAnalytics, enableCrisp, enableVercelPreviewFeedback } =
    publicEnvironment;

  const crispUrl = crisp.CRISP_URL;
  const gtmPreviewUrl = google.GTM_PREVIEW_URL;
  const tmPreviewUrl = google.TM_PREVIEW_URL;
  const vercelLiveUrl = vercel.LIVE_URL;
  const facebookUrl = externalAnalytics.FACEBOOK_URL;

  let hostAllowed = `${host}`;
  if (env === 'preview') {
    hostAllowed = `https://${process.env.NEXT_PUBLIC_VERCEL_URL} https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`;
  }

  let styleSrc = [
    `'self'`,
    cdnCsp,
    `'unsafe-inline'`,
    ONFIDO_ASSETS_URL,
    ONFIDO_SDK_URL,
    hostAllowed,
  ];
  if (enableVercelPreviewFeedback) {
    styleSrc = styleSrc.concat([vercelLiveUrl]);
  }
  if (enableCrisp) {
    styleSrc = styleSrc.concat([crispUrl]);
  }
  if (enableGoogleAnalytics) {
    styleSrc = styleSrc.concat([gtmPreviewUrl, facebookUrl, tmPreviewUrl]);
  }

  return styleSrc.filter((src) => src);
};
