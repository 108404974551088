import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import styled from 'styled-components';

import { Box, Icon, InputBase } from '@core/component';
import { Translate } from '@core/constant';
import { EActionAttribute, EColor, EIcon } from '@core/type';

import { SearchInputProps } from './interface-search';

export const SearchInput: FC<SearchInputProps> = ({ onChange, onPressEnter, value, isLight }) => {
  const { t } = useTranslation(Translate.layout.HEADER);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      onPressEnter();
      onChange('');
    }
  };

  return (
    <Box
      as={EActionAttribute.LABEL}
      maxWidth={'400px'}
      paddingRight={'18px'}
      height={'40px'}
      borderRadius={'10px'}
      backgroundColor={
        isLight || isFocused
          ? {
              commonType: EColor.WHITE,
              intensity: EColor.R900,
            }
          : {
              commonType: EColor.GRAY,
              intensity: EColor.A50,
            }
      }
      borderWidth={'1px'}
      borderColor={{ commonType: EColor.GRAY, intensity: EColor.R100 }}
      display={'flex'}
      alignItems={'center'}
      flex={'1'}
    >
      <Box
        marginRight={'6px'}
        padding={'10px 18px'}
        cursor={'pointer'}
        onClick={() => {
          onPressEnter();
          onChange('');
        }}
      >
        <Icon type={EIcon.SEARCH} width={'18px'} height={'18px'} flexShrink={'0'} />
      </Box>

      <StyledInput
        placeholder={t('search.searchAProduct')}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        value={value}
      />
    </Box>
  );
};

const StyledInput = styled(InputBase)`
  font-size: 14px;
  text-overflow: ellipsis;
`;
