import { useEventMixpanel } from '@mixpanel';

import { LinkTranslatedStyledAsLink, Typography } from '@core/component';
import { usePageLoading } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EMixpanelHeaderSectionType,
  HeaderMenuFullwidthDropdownProps,
} from '@core/type';

import { MenuItemContainer } from './MenuItemContainer';
import { HoveredMenuHandler } from './interface-header-menu';
import { getHeaderLinkHref } from './utils';

export const MenuFullwidthDropdown = ({
  link,
  subMenus,
  onHover,
  index,
}: HeaderMenuFullwidthDropdownProps & {
  onHover: HoveredMenuHandler;
  index: number;
}) => {
  const { trackHeaderEvents } = useEventMixpanel();
  const { isLoading, startLoading } = usePageLoading();
  const href = getHeaderLinkHref(link);

  const handleClick = () => {
    startLoading();
    trackHeaderEvents({
      sectionName: link.text,
      sectionType: EMixpanelHeaderSectionType.MENU,
      urlPath: href,
    });
  };

  return (
    <MenuItemContainer onHover={onHover} index={index} shouldShowBorder={subMenus.length > 0}>
      <LinkTranslatedStyledAsLink
        as={EActionAttribute.LINK}
        href={href}
        isUnderlinedHover={false}
        color={isLoading ? EColor.ACCENT : undefined}
        onClick={handleClick}
      >
        <Typography as={EActionAttribute.SPAN} fontWeight={EFontWeight.BOLD} whiteSpace={'nowrap'}>
          {link.text}
        </Typography>
      </LinkTranslatedStyledAsLink>
    </MenuItemContainer>
  );
};
