import { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@core/component';
import { HEADER_CONTAINER_WIDTH, util, zIndexes } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useScrollDirection } from '@core/hook';
import { EColor, EHeaderMenu, EZIndexName, WithThemeProps } from '@core/type';
import { getPaletteHandlers } from '@core/util';

import { ButtonCart } from '../button';
import { Dropdown } from './Dropdown';
import { MenuItemsBlock } from './MenuItemsBlock';
import { SearchBlock } from './SearchBlock';
import type {
  HeaderMenuProps,
  HoveredMenuHandler,
  HoveredMenuProps,
} from './interface-header-menu';

export const HeaderMenu = ({ menus }: HeaderMenuProps) => {
  const { isMobile } = useContextMediaQuery();
  const [hoveredMenuProps, setHoveredMenuProps] = useState<HoveredMenuProps>({
    styleProps: { left: 0, width: 0 },
    index: null,
  });
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { isDown } = useScrollDirection(util.DEBOUNCE);

  const handleHover: HoveredMenuHandler = (props) => {
    setHoveredMenuProps(props);
  };

  const handleMouseLeave = () => {
    setHoveredMenuProps((prevState) => ({
      styleProps: prevState?.styleProps?.width ? prevState.styleProps : null,
      index: null,
    }));
  };

  const handleSearchOpen = () => {
    setIsSearchOpen(true);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  return (
    <StyledMenuWrapper
      onMouseLeave={handleMouseLeave}
      padding={isMobile ? '6px 20px' : '10px 20px'}
    >
      <StyledBackground shouldBlurBackground={isDown} />
      <StyledBox overflowX={isSearchOpen ? undefined : 'auto'}>
        <Box
          maxWidth={`${HEADER_CONTAINER_WIDTH}px`}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          margin={'0 auto'}
          gap={'24px'}
          height={'100%'}
        >
          <SearchBlock
            isSearchOpen={isSearchOpen}
            handleSearchOpen={handleSearchOpen}
            handleSearchClose={handleSearchClose}
          />
          <MenuItemsBlock menus={menus} handleHover={handleHover} isSearchOpen={isSearchOpen} />
          {isMobile ? <Box /> : <ButtonCart />}
        </Box>
        {!isMobile && (
          <Box
            position={'absolute'}
            bottom={'0'}
            width={'0'}
            opacity={hoveredMenuProps?.index === null ? '0' : '1'}
            borderWidth={'2px'}
            borderRadius={'10px 10px 0 0'}
            borderColor={EColor.PRIMARY}
            backgroundColor={EColor.PRIMARY}
            transition={'all 0.3s'}
            {...hoveredMenuProps?.styleProps}
          />
        )}
      </StyledBox>
      {menus?.map((menu, index) => {
        if (
          !isMobile &&
          [EHeaderMenu.FULLWIDTH_DROPDOWN, EHeaderMenu.SHORT_DROPDOWN].includes(menu.menuType)
        ) {
          return (
            <Dropdown
              key={index}
              index={index}
              menu={menu}
              hoveredMenuProps={hoveredMenuProps}
              onClickLink={handleMouseLeave}
            />
          );
        }
        return null;
      })}
    </StyledMenuWrapper>
  );
};

const StyledBackground = styled(Box)(({
  shouldBlurBackground,
  theme: { palette },
}: WithThemeProps & {
  shouldBlurBackground: boolean;
}) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  const backgroundColor = getBackgroundColor({ commonType: EColor.GRAY, intensity: EColor.A50 });
  const borderColor = getBackgroundColor({ commonType: EColor.GRAY, intensity: EColor.A200 });
  const hoverBorderColor = getBackgroundColor({ commonType: EColor.WHITE, intensity: EColor.R900 });
  const transparent = getBackgroundColor(EColor.TRANSPARENT);

  return css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
    background-color: ${backgroundColor};
    border-color: ${borderColor};
    border-width: 1px;
    border-style: solid;

    ${shouldBlurBackground
      ? css`
          backdrop-filter: blur(10px);
          background-color: ${transparent};
          border-color: ${hoverBorderColor};
        `
      : ''}
  `;
});

const StyledMenuWrapper = styled(Box)(({ theme: { palette } }: WithThemeProps) => {
  const { getBackgroundColor } = getPaletteHandlers(palette);

  const backgroundColor = getBackgroundColor({
    semanticType: EColor.SECONDARY,
    variant: EColor.LIGHT,
  });
  const backgroundBorderColor = getBackgroundColor({
    commonType: EColor.GRAY,
    intensity: EColor.A200,
  });

  return css`
    position: relative;
    padding: 0 20px;

    @media (hover: hover) {
      &:hover ${StyledBackground} {
        background-color: ${backgroundColor};
        border-color: ${backgroundBorderColor};
      }
    }
  `;
});

const StyledBox = styled(Box)`
  height: 54px;
  width: 100%;
  max-width: 100vw;
  position: relative;
  scrollbar-color: transparent transparent !important;
  z-index: ${zIndexes[EZIndexName.OVER_DEFAULT]};

  &::-webkit-scrollbar {
    display: none;
  }
`;
