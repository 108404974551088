import {
  Alternates,
  EAlternateLevels,
  ELanguage,
  ELanguageTags,
  ERouting,
  ERoutingDynamic,
} from '@core/type';
import { AlternatesEntity, AlternatesLanguageEntity } from '@core/type/api';

import { getLanguageTags } from './language';
import { getTypeRoute } from './routing';

const getSlug = (keyRoute: ERoutingDynamic, alternates?: AlternatesLanguageEntity[]) =>
  alternates?.find(({ alternateType, isAvailable }) =>
    isAvailable === false ? false : alternateType === keyRoute,
  )?.slug;

const getSlugByLevel = (level: EAlternateLevels, alternates?: AlternatesLanguageEntity[]) =>
  alternates?.[level]?.slug;

export const getDynamicAlternate = ({
  alternates,
  keyRouteDynamic,
  keyRouteParent,
  query,
}: {
  alternates?: AlternatesLanguageEntity[];
  keyRouteDynamic: ERoutingDynamic;
  keyRouteParent?: ERouting;
  query?: NodeJS.Dict<string | string[]>;
}): {
  keyRoute: ERouting;
  query?: NodeJS.Dict<string | string[]>;
} => {
  switch (keyRouteDynamic) {
    case ERoutingDynamic.CHARTS:
      return {
        keyRoute: ERouting.CHARTS,
        query: {
          ...query,
          metalSlug: getSlug(ERoutingDynamic.METAL_SLUG, alternates),
          slug: getSlug(ERoutingDynamic.SLUG, alternates),
        },
      };
    case ERoutingDynamic.PAGES:
      return {
        keyRoute: ERouting.PAGES,
        query: {
          ...query,
          slug: getSlug(ERoutingDynamic.SLUG, alternates),
        },
      };
    case ERoutingDynamic.BLOG_ARTICLE: {
      const category = alternates?.find(
        ({ alternateType }) => alternateType === ERoutingDynamic.BLOG_CATEGORY,
      )?.slug;
      const slug = getSlug(ERoutingDynamic.BLOG_ARTICLE, alternates);

      if (slug && category) {
        return {
          keyRoute: ERouting.BLOG_ARTICLE,
          query: {
            ...query,
            slug,
            category,
          },
        };
      }

      return null;
    }
    case ERoutingDynamic.BLOG_CATEGORY: {
      const slug = getSlug(ERoutingDynamic.BLOG_CATEGORY, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.BLOG_CATEGORY,
          query: {
            ...query,
            slug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.BLOG_TAG: {
      const slug = getSlug(ERoutingDynamic.BLOG_TAG, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.BLOG_TAG,
          query: {
            ...query,
            slug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.FAQ_ARTICLE: {
      const slug = getSlug(ERoutingDynamic.FAQ_ARTICLE, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.FAQ_SHOW,
          query: {
            ...query,
            slug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.METAL: {
      const metal = getSlug(ERoutingDynamic.SLUG, alternates);
      if (metal) {
        return {
          keyRoute: ERouting.BUY,
          query: {
            ...query,
            metal,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.METAL_CATEGORY: {
      const slug = getSlug(ERoutingDynamic.SLUG, alternates);
      const metal = getSlug(ERoutingDynamic.METAL_SLUG, alternates);
      if (slug && metal) {
        return {
          keyRoute: ERouting.SHOP_SUBCATEGORY,
          query: {
            ...query,
            slug,
            metal,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.PRODUCT_SHOW: {
      const slug = getSlug(ERoutingDynamic.SLUG, alternates);
      const metal = getSlug(ERoutingDynamic.METAL_SLUG, alternates);
      if (slug && metal) {
        return {
          keyRoute: ERouting.PRODUCT_SHOW,
          query: {
            ...query,
            metal,
            slug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.TUTORIAL_VIDEO: {
      const slug = getSlug(ERoutingDynamic.TUTORIAL_VIDEO, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.TUTORIAL_VIDEO,
          query: {
            ...query,
            slug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE: {
      return {
        keyRoute: ERouting.PRECIOUS_METAL_GUIDE,
      };
    }
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL1: {
      const slug = getSlugByLevel(EAlternateLevels.LEVEL2, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.PRECIOUS_METAL_GUIDE_LEVEL1,
          query: {
            ...query,
            slug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL2: {
      const slug = getSlugByLevel(EAlternateLevels.LEVEL2, alternates);
      const subSlug = getSlugByLevel(EAlternateLevels.LEVEL3, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.PRECIOUS_METAL_GUIDE_LEVEL2,
          query: {
            ...query,
            slug,
            subSlug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    case ERoutingDynamic.PRECIOUS_METAL_GUIDE_LEVEL3: {
      const slug = getSlugByLevel(EAlternateLevels.LEVEL2, alternates);
      const subSlug = getSlugByLevel(EAlternateLevels.LEVEL3, alternates);
      const subSubSlug = getSlugByLevel(EAlternateLevels.LEVEL4, alternates);
      if (slug) {
        return {
          keyRoute: ERouting.PRECIOUS_METAL_GUIDE_LEVEL3,
          query: {
            ...query,
            slug,
            subSlug,
            subSubSlug,
          },
        };
      }
      return {
        keyRoute: keyRouteParent,
      };
    }
    default:
      return {
        keyRoute: keyRouteParent || ERouting.HOME,
      };
  }
};

export const buildAlternate = ({
  alternates,
  keyRoute,
  query,
}: {
  alternates?: AlternatesEntity;
  keyRoute: ERouting;
  query?: NodeJS.Dict<string | string[]>;
}): Alternates[] => {
  if (alternates) {
    return Object.values(ELanguage)
      .filter((tag) => tag !== ELanguage.DEFAULT)
      .map((tag) => {
        const dynamicAlternate = getDynamicAlternate({
          alternates: alternates[tag as Exclude<ELanguage, 'default'>],
          keyRouteDynamic: getTypeRoute(keyRoute).keyRouteDynamic,
          query,
          keyRouteParent: getTypeRoute(keyRoute).keyRouteParent,
        });

        if (!alternates[tag as Exclude<ELanguage, 'default'>] || !dynamicAlternate) {
          return [
            ERouting.BLOG_ARTICLE,
            ERouting.BLOG_CATEGORY,
            ERouting.BLOG_SEARCH,
            ERouting.BLOG_TAG,
            ERouting.BLOG_HOME,
          ].includes(keyRoute)
            ? { keyRoute: ERouting.BLOG_HOME, language: getLanguageTags(tag as ELanguage) }
            : { keyRoute: ERouting.HOME, language: getLanguageTags(tag as ELanguage) };
        }

        return {
          ...dynamicAlternate,
          language: getLanguageTags(tag as ELanguage) as Exclude<ELanguageTags, 'default'>,
        };
      })
      .filter((alternate) => alternate);
  } else if (getTypeRoute(keyRoute).keyRouteDynamic) {
    return Object.values(ELanguageTags)
      .filter((tag) => tag !== ELanguageTags.DEFAULT)
      .map((tag) => ({
        keyRoute: getTypeRoute(keyRoute).keyRouteParent,
        language: tag,
      })) as Alternates[];
  }

  return Object.values(ELanguageTags)
    .filter((tag) => tag !== ELanguageTags.DEFAULT)
    .map((tag) => ({ keyRoute, query, language: tag })) as Alternates[];
};
