import { useLanguage } from '@core/hook';
import { EActionAttribute, EPosition } from '@core/type';

import { ErrorDot } from '../error-dot';
import { Icon } from '../icon';
import { BaseBoxProps, Box } from '../layout';
import { Link } from '../link';
import { LinkTranslatedStyledAsLink } from '../link-translated';
import { Typography } from '../typography';
import { DrawerSub } from './DrawerSub';
import { DrawerLineProps } from './interface-drawer';

const DrawerTextLine = ({
  children,
  title,
  iconType,
}: Pick<DrawerLineProps, 'children' | 'title' | 'iconType'>) => (
  <Box display={'flex'} alignItems={'center'} justifyContent="space-between">
    <Box>
      {iconType && (
        <Icon type={iconType} display="inline-block" marginRight="10px" verticalAlign="middle" />
      )}
      <Typography as={EActionAttribute.SPAN} display="inline-block">
        {title}
      </Typography>
    </Box>
    {children}
  </Box>
);

const DrawerLinkLine = ({
  children,
  title,
  route,
  query,
  isInvalid,
  closeDrawer,
  target,
  href,
  iconType,
  trackEvent,
}: DrawerLineProps) => {
  const { language } = useLanguage();

  const handleClick = () => {
    closeDrawer();
    trackEvent?.();
  };

  return route || href ? (
    <LinkTranslatedStyledAsLink
      keyRoute={route}
      query={query}
      onClick={handleClick}
      target={target}
      language={language}
      href={href}
      isUnderlinedHover={false}
    >
      <DrawerTextLine title={title} iconType={iconType}>
        {children}
        {isInvalid && <ErrorDot />}
      </DrawerTextLine>
    </LinkTranslatedStyledAsLink>
  ) : (
    <Link onClick={closeDrawer}>
      <DrawerTextLine title={title} iconType={iconType}>
        {children}
        {isInvalid && <ErrorDot />}
      </DrawerTextLine>
    </Link>
  );
};

export const DrawerLine = ({
  children,
  title,
  titleContent,
  route,
  query,
  drawerLevel,
  drawerLevelProps,
  handleDrawerLevelProps,
  isInvalid,
  target,
  href,
  hasIcon,
  isBlogPage,
  iconType,
  closeDrawer,
  trackEvent,
  ...restProps
}: DrawerLineProps & BaseBoxProps) => (
  <Box as={EActionAttribute.LI} padding="8px 40px 8px 40px" {...restProps}>
    {children ? (
      <DrawerSub
        title={title}
        titleContent={titleContent}
        drawerLevel={drawerLevel}
        position={EPosition.LEFT}
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        size="100%"
        hasIcon={hasIcon}
        isBlogPage={isBlogPage}
        trackEvent={trackEvent}
      >
        {children}
      </DrawerSub>
    ) : (
      <DrawerLinkLine
        closeDrawer={closeDrawer}
        drawerLevelProps={drawerLevelProps}
        title={title}
        route={route}
        query={query}
        isInvalid={isInvalid}
        target={target}
        href={href}
        iconType={iconType}
        trackEvent={trackEvent}
      />
    )}
  </Box>
);
