import { EMetal } from './metal';
import { ESortQuery } from './util';

export enum EShopQuery {
  METAL = 'metal',
  METALS = 'metals',
  PRODUCT_TYPES = 'product_types',
  PRODUCT_PRICES = 'product_prices',
  PRODUCT_WEIGHTS = 'product_weights',
  PRICE_SORT = 'price_sort',
  COLLECTIONS = 'collections',
  BRANDS = 'brands',
  SELECTIONS = 'selections',
}

export enum ESelectionsFilters {
  DEALS = 'deals',
  AUTO_SAVINGS = 'auto-savings',
}

export type ShopQuery = ShopFilterQuery & {
  [EShopQuery.PRICE_SORT]: ESortQuery;
};

export type ShopFilterQuery = {
  [EShopQuery.METAL]: string[];
  [EShopQuery.METALS]: EMetal[];
  [EShopQuery.PRODUCT_TYPES]: string[];
  [EShopQuery.PRODUCT_PRICES]: string[];
  [EShopQuery.PRODUCT_WEIGHTS]: string[];
  [EShopQuery.COLLECTIONS]: string[];
  [EShopQuery.BRANDS]: string[];
  [EShopQuery.SELECTIONS]: string[];
};
