import { Translate as NextTranslate } from 'next-translate';
import type { ReactNode } from 'react';

import { useContextRouting, useContextToast, useContextUser, utilContext } from '@core/context';
import { ECountry, ECurrency, EDevice, ELanguageTags } from '@core/type';
import { UtilHook, UtilState } from '@core/type/context';
import { getHttpClientConfig } from '@core/util';

import { storeHook } from '../helpers/store';
import { useUtil } from '../hook';

export const ProviderUtil = ({
  children,
  currency,
  language,
  apiHost,
  apiBase,
  apiVersion,
  isProductionServer,
  deviceType,
  tCommon,
  ...restProps
}: {
  apiHost?: string;
  apiBase?: string;
  apiVersion?: string;
  isProductionServer?: boolean;
  language: ELanguageTags;
  currency: ECurrency;
  deviceType?: EDevice;
  nonce: string;
  csrfToken: string;
  recaptchaKey: string;
  isBotDetected?: boolean;
  cdn: string;
  staleTimeClient: number;
  tCommon?: NextTranslate;
  children?: ReactNode;
  clientIp: string;
  userLocation: ECountry;
}) => {
  const { addToast } = useContextToast();
  const { keyRoute, setRoute } = useContextRouting();
  const { setStatus, setIsDisconnecting, isDisconnecting } = useContextUser();

  const axiosInstance = getHttpClientConfig({
    currency,
    keyRoute,
    language,
    apiHost,
    apiBase,
    csrfToken: restProps?.csrfToken,
    apiVersion,
    isProductionServer,
    setStatus,
    addToast,
    setRoute,
    tCommon,
    deviceType,
    isDisconnecting,
    setIsDisconnecting,
  });

  const hooks = storeHook<UtilState, UtilHook>({ axiosInstance, ...restProps }, useUtil);
  return <utilContext.Provider value={hooks}>{children}</utilContext.Provider>;
};
