import { EShopQuery } from '@core/type';

export const MIXPANEL_API_URL = 'https://api-js.mixpanel.com';

export const MIXPANEL_FILTER_QUERIES = [
  EShopQuery.METALS,
  EShopQuery.PRODUCT_TYPES,
  EShopQuery.COLLECTIONS,
  EShopQuery.SELECTIONS,
  EShopQuery.PRODUCT_PRICES,
  EShopQuery.PRODUCT_WEIGHTS,
  EShopQuery.BRANDS,
];
